html,
body {
  -webkit-font-smoothing: antialiased;
  font-family: "PingFang SC", "Microsoft YaHei", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,
    "Hiragino Sans GB", SimSun, sans-serif;
  word-break: break-all;
  line-height: 1.5;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
  user-select: none;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#root {
  height: 100%;
}

.main {
  // background: url(./mid-bg.5573edb.png);
  background-repeat: repeat;
  position: relative;
  width: 100vw;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 6px;
  height: 100vh;
  background-size: cover;
  /* border: 10px solid #fff; */
  box-sizing: border-box;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  .navbar {
    padding: 12px 12px 0 12px;
    font-size: 18px;
    background-color: #fff;
    color: #b6b7ae;
    // border-bottom: 1px solid #b6b7ae;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;

    .menu {
      font-size: 14px;
      text-align: right;

      .menu-item {
        display: inline-block;
        text-align: center;
        position: relative;
        padding-right: 18px;
        font-weight: 600;
        line-height: 20px;

        &:hover,
        &:focus {
          .sub-nav-menu-list {
            display: block;
          }
        }
      }

      .sub-nav-menu-list {
        display: none;
        position: absolute;
        top: 100%;
        left: -80px;
        background: #fff;
        padding: 0;
        z-index: 999;
        // text-align: left;
        padding: 16px 24px;
        /* display: inline-block; */
        width: 160px;
        border: 1px solid #e6e6e6;

        cursor: pointer;

        .nav-tags-item {
          line-height: 18px;
          font-weight: 600;
          border-radius: 4px;
          padding-bottom: 6px;
        }
      }
    }

    .hamburger {
      position: relative;
      float: right;
      padding-top: 2px;

      .hamburger-toggle {
        border: none;
        position: relative;
        float: right;
        transition: transform 0.2s ease-in-out;
      }

      .humburger-bar {
        display: block;
        width: 28px;
        height: 3px;
        margin-bottom: 6px;
        background: #b6b7ae;
        border-radius: 2px;
      }
    }
  }

  .pdf-viewer {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-flow: column;
    flex-wrap: nowrap;
    background-color: #fff;
    position: relative;
    height: 100%;
    margin: 0px auto;
    justify-content: center;

    .page-next {
      text-align: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 20px;
      line-height: 32px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      right: 12px;
      top: 50%;
    }

    .page-prev {
      text-align: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 20px;
      line-height: 32px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      left: 12px;
      top: 50%;
    }

    .page-number {
      position: absolute;
      left: 6px;
      top: 0px;
      padding: 4px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 16px;
    }

    .file-download {
      position: absolute;
      left: 48px;
      top: 0px;
      padding: 4px;

      /* background-color: rgba(0, 0, 0, 0.8); */
      a {
        padding: 0 8px;
        color: rgba(0, 0, 0, 0.8);
        font-size: 24px;
        line-height: 24px;

        &:hover,
        &:active {
          color: rgba(0, 0, 0, 0.8);
        }
      }

      //       a:hover,
      // .main .pdf-viewer a:active {
      //   color: rgba(0, 0, 0, 0.8);
      // }
    }

    .react-pdf__Page {
      text-align: center;
      width: 100vw;

      .react-pdf__Page__svg {
        width: 100% !important;
        height: auto !important;

        canvas {
          padding-left: 0;
          padding-right: 0;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          max-width: 100%;
          height: auto !important;
        }

        svg {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }

  .container-view-wrapper {
    width: 100%;
    // height: 690px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    .nav-tags {
      // position: absolute;
      // left: 0;
      // bottom: 0;
      // display: flex;
      // flex-wrap: nowrap;
      // width: 100%;
      // flex-direction: row;
      // justify-content: space-around;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 20px;
      align-content: flex-end;
      z-index: 123;

      .nav-tags-item {
        font-size: 18px;
        font-weight: 900;
        color: #848484;
        padding: 4px 6px;
        min-width: 100px;
        text-align: center;
        // border: 2px solid rgba(0, 0, 0, 0.29);
        box-shadow: 0px 1px 19px 0px rgba(0, 0, 0, 0.29);
      }
    }

    .container-view {
      background-image: url("/images/2021-00.jpg");
      background-repeat: no-repeat;
      width: 100%;
      height: 600px;
      background-size: contain;
      position: relative;
      background-position: center;

      .mini-view-box {
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        z-index: 100;


        .mini-view-item {
          max-width: 400px;
          padding: 10px;

          img {
            width: 100%;
          }
        }
      }

      .mini-nav-view-box-wrapper {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        box-sizing: border-box;
        z-index: 100;
        // padding-top: 42px;

        .mini-nav-view-box {
          width: 100%;
          // height: 100%;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
          // overflow: hidden;

          .mini-view-item {
            max-width: 400px;
            padding: 10px;

            img {
              width: 100%;
            }
          }

          .mini-view-nav-tag {
            max-width: 400px;
            margin: 6px;

            img {
              transition-duration: 200ms;
              box-shadow: 2px 1px 12px 4px rgba(0, 0, 0, 0.3);
              min-width: 208px;
              min-height: 123px;
              max-width: 256px;

              .active {
                transform: scale(1.15);
              }

              &:focus,
              &:hover {
                transform: scale(1.15);
              }

            }

            img.active {
              transform: scale(1.15);
            }
          }
        }

        .mini-sub-view-box {
          height: 400px;

          // .swiper-container {
          //   height: 100%;
          // }
          .mini-view-nav-sub-item {
            height: 400px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }

  .home-index {
    background: none !important;
  }
}

.swiper-slide {
  transform-style: inherit !important;
}

.swiper-container-3d {
  .swiper-slide-shadow-left {
    transform-style: inherit !important;
    background-image: none !important;
  }

  .swiper-slide-shadow-right {
    transform-style: inherit !important;
    background-image: none !important;
  }

}

.swiper-button-prev,
.swiper-button-next {
  color: #b6b7ae !important;
}

@media screen and (max-width: 767px) {
  .main .pdf-viewer {
    width: 100vw;
  }
}

.container {
  text-align: center;
  position: absolute;
  min-width: 240px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title {
    font-size: 22px;
    margin: 0.5em;
    animation-fill-mode: both;
    animation-duration: 2s;
    animation-name: fadeInDown;
  }

  b {
    padding: 0 2px;
    color: #fff;
    animation: letter-glow 1.2s ease 0s both;
  }

  .qrcode {
    width: 72%;
    margin: 0 auto;
    animation-name: shake-rotate;
    animation-delay: 1.6s;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;

    img {
      width: 100%;
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin: 2px 0;
  color: #e6e6e6;

  a {
    color: #e6e6e6;

    &:hover {
      color: #1890ff;
    }
    &:focus {
      color: #1890ff;
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes letter-glow {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.6;
  }

  77% {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
}

@keyframes shake-rotate {
  2% {
    transform: translate(0, 0) rotate(6.5deg);
  }

  4% {
    transform: translate(0, 0) rotate(3.5deg);
  }

  6% {
    transform: translate(0, 0) rotate(-3.5deg);
  }

  8% {
    transform: translate(0, 0) rotate(5.5deg);
  }

  10% {
    transform: translate(0, 0) rotate(-4.5deg);
  }

  12% {
    transform: translate(0, 0) rotate(-4.5deg);
  }

  14% {
    transform: translate(0, 0) rotate(-6.5deg);
  }

  16% {
    transform: translate(0, 0) rotate(2.5deg);
  }

  18% {
    transform: translate(0, 0) rotate(-5.5deg);
  }

  20% {
    transform: translate(0, 0) rotate(0.5deg);
  }

  22% {
    transform: translate(0, 0) rotate(-3.5deg);
  }

  24% {
    transform: translate(0, 0) rotate(-6.5deg);
  }

  26% {
    transform: translate(0, 0) rotate(6.5deg);
  }

  28% {
    transform: translate(0, 0) rotate(1.5deg);
  }

  30% {
    transform: translate(0, 0) rotate(-5.5deg);
  }

  32% {
    transform: translate(0, 0) rotate(7.5deg);
  }

  34% {
    transform: translate(0, 0) rotate(-3.5deg);
  }

  36% {
    transform: translate(0, 0) rotate(7.5deg);
  }

  38% {
    transform: translate(0, 0) rotate(-5.5deg);
  }

  40% {
    transform: translate(0, 0) rotate(3.5deg);
  }

  42% {
    transform: translate(0, 0) rotate(6.5deg);
  }

  44% {
    transform: translate(0, 0) rotate(1.5deg);
  }

  46% {
    transform: translate(0, 0) rotate(3.5deg);
  }

  48% {
    transform: translate(0, 0) rotate(-0.5deg);
  }

  50% {
    transform: translate(0, 0) rotate(-4.5deg);
  }

  52% {
    transform: translate(0, 0) rotate(4.5deg);
  }

  54% {
    transform: translate(0, 0) rotate(-3.5deg);
  }

  56% {
    transform: translate(0, 0) rotate(4.5deg);
  }

  58% {
    transform: translate(0, 0) rotate(-3.5deg);
  }

  60% {
    transform: translate(0, 0) rotate(5.5deg);
  }

  62% {
    transform: translate(0, 0) rotate(6.5deg);
  }

  64% {
    transform: translate(0, 0) rotate(3.5deg);
  }

  66% {
    transform: translate(0, 0) rotate(-3.5deg);
  }

  68% {
    transform: translate(0, 0) rotate(-4.5deg);
  }

  70% {
    transform: translate(0, 0) rotate(-6.5deg);
  }

  72% {
    transform: translate(0, 0) rotate(6.5deg);
  }

  74% {
    transform: translate(0, 0) rotate(-5.5deg);
  }

  76% {
    transform: translate(0, 0) rotate(-6.5deg);
  }

  78% {
    transform: translate(0, 0) rotate(5.5deg);
  }

  80% {
    transform: translate(0, 0) rotate(-6.5deg);
  }

  82% {
    transform: translate(0, 0) rotate(0.5deg);
  }

  84% {
    transform: translate(0, 0) rotate(4.5deg);
  }

  86% {
    transform: translate(0, 0) rotate(5.5deg);
  }

  88% {
    transform: translate(0, 0) rotate(-6.5deg);
  }

  90% {
    transform: translate(0, 0) rotate(-5.5deg);
  }

  92% {
    transform: translate(0, 0) rotate(4.5deg);
  }

  94% {
    transform: translate(0, 0) rotate(2.5deg);
  }

  96% {
    transform: translate(0, 0) rotate(-5.5deg);
  }

  98% {
    transform: translate(0, 0) rotate(2.5deg);
  }

  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

.loading-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(170, 170, 170, 1);
  z-index: 999;
}

.ball-clip-rotate-multiple {
  position: relative;
}

.ball-clip-rotate-multiple>div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  -webkit-animation: rotate 1s 0s ease-in-out infinite;
  animation: rotate 1s 0s ease-in-out infinite;
}

.ball-clip-rotate-multiple>div:last-child {
  display: inline-block;
  top: 10px;
  left: 10px;
  width: 15px;
  height: 15px;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  border-color: #fff transparent #fff transparent;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ball-scale-ripple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.ball-scale-ripple>div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 2px solid #fff;
  -webkit-animation: ball-scale-ripple 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  animation: ball-scale-ripple 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}

@keyframes ball-scale-ripple-multiple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.loader-inner {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 9999999;
}

.qrcode-loader {
  top: 50%;
  left: 50%;
  width: 65px;
  height: 65px;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}